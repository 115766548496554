<template>
  <div id="app">
    <Navigation />
    <router-view />
    <div ><img class="ai-btn" :src="aiAvatar" alt="" @click="jumpToAi"></div>
    <div>
  </div>
  </div>
</template>

<script>
import Navigation from './components/Navigation/Navigation.vue'
import aiAvatar from '@/assets/ai-avatar.png'
export default {
  name: 'App',
  components: {
    Navigation,
  },
  data () {
    return {
      aiAvatar,
    }
  },
  /**
   *
   */
  methods: {
    jumpToAi () {
      window.open('https://rag.c2m-mom.com/chat/x7S0szvkeyYRuekf')
    },
  },
}

</script>

<style>
.ai-btn{
  width: 100px;
  height:100;
  position: fixed;
  right: 30px;
  bottom: 30px;
  cursor: pointer;
}

</style>
